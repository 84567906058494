
import './ResultadosPage.css'
import React, { useState, useEffect } from 'react';
import { getEncuestas } from '../../services/getEncuestas'
import logo from './../../assets/iconos/logo.png';
import { setEncuestaActiva } from '../../services/setEncuestaActiva';
import { useLocation } from 'react-router-dom';
import { getHistoria } from '../../services/getHistoria';


import icono_1 from './../../assets/iconos/cara_1.png';
import icono_2 from './../../assets/iconos/cara_2.png';
import icono_3 from './../../assets/iconos/cara_3.png';
import icono_4 from './../../assets/iconos/cara_4.png';
import icono_5 from './../../assets/iconos/cara_5.png';
import Boton from '../../components/boton/Boton';
import { ICONOS_BOTON } from '../../utils/Const';

import { useSelector } from 'react-redux';
import { getResultadoEncuestaEnExcel } from '../../services/getResultadoEncuestaEnExcel';

function ResultadosPage() {

    const userData = useSelector((state) => state.authInfo);

    // console.log(route)
    const [historia, setHistoria] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [encuestasFiltradas, setEncuestasFiltradas] = useState([])
    const [isLogged, setIsLogged] = useState(false)
    const [error, setError] = useState("")
    const [filtro, setFiltro] = useState("")
    const [idFormulario, setIdFormulario] = useState(null)
    const [checked, setChecked] = useState(false);

    // const { encuesta } = route.params;
    const { state } = useLocation();
    const encuesta = state?.encuesta || null;
    console.log(!encuesta ? encuesta : "No hay encuesta que mostrar")


    useEffect(() => {
    }, [])

    useEffect(() => {
        buscaHistoria()
    }, [encuesta])

    useEffect(() => {
        usaFiltro()
    }, [encuestas])

    const usaFiltro = () => {
        setEncuestasFiltradas(encuestas.filter(x => x.descripcion_larga.toUpperCase().includes(filtro.toUpperCase()) || x.glosa_encuesta.toUpperCase().includes(filtro.toUpperCase())))
    }

    const buscaHistoria = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const resultado = await getHistoria(userData, encuesta.id_encuesta);
                console.log(resultado)
                if (resultado)
                    // props.handleLogged(isLogged)
                    setHistoria(resultado)
                else
                    setError("Usuario/Password incorrecto")
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }

    const handleTextoChange = (event) => {
        setFiltro(event.target.value)
    }

    const handleCheckboxChange = (id_encuesta) => {
        console.log("Cambiando id_encuesta", id_encuesta)
        const fetchData = async () => {
            try {
                const resultado = await setEncuestaActiva(id_encuesta);
                console.log("resultado", resultado)
                if (resultado)
                    // props.handleLogged(isLogged)
                    buscaHistoria()
                else
                    setError("Usuario/Password incorrecto")
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    };


    const exportar = () => {
        const fetchData = async () => {
            try {
                await getResultadoEncuestaEnExcel(userData, encuesta.id_encuesta);
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();

    }


    return (
        <div className="App" style={{ marginLeft: "20px" }}>
            {/* <div>
                {!isLogged && (<LoginPage handleLogged={handleLogged}></LoginPage>)}
            </div> */}
            <h1>
                Resultados '{encuesta.glosa_encuesta}'
            </h1>
            <h2>Estos son los resultados de las encuestas</h2>
            <a onClick={() => exportar()}><Boton icono={ICONOS_BOTON.EXPORTAR} texto={"Exportar"} ></Boton></a>


            <div className='header'>
                <p style={{ width: "600px", fontWeight: 'bold', fontSize: '16px' }}>Pregunta</p>
                <p style={{ width: "300px", fontWeight: 'bold', fontSize: '16px' }}>Resultado</p>
            </div>

            {historia.map((pregunta, index) => (
                <div className='div-resultados' key={index}>
                    <div className='div-pregunta' >
                        <p>{pregunta.pregunta}</p>
                    </div>
                    <div className='div-resultado' >
                        {pregunta.resultados.map((puntaje, index) => (


                            <div className='div-puntaje'>

                                <div style={{ marginLeft: 10, width: 200 }} >
                                    {pregunta.tipo_de_pregunta == 1 && puntaje.pregunta == ':((' ? (<img width={50} src={icono_1} />) : null}
                                    {pregunta.tipo_de_pregunta == 1 && puntaje.pregunta == ':(' ? (<img width={50} src={icono_2} />) : null}
                                    {pregunta.tipo_de_pregunta == 1 && puntaje.pregunta == ':|' ? (<img width={50} src={icono_3} />) : null}
                                    {pregunta.tipo_de_pregunta == 1 && puntaje.pregunta == ':)' ? (<img width={50} src={icono_4} />) : null}
                                    {pregunta.tipo_de_pregunta == 1 && puntaje.pregunta == ':))' ? (<img width={50} src={icono_5} />) : null}

                                    {pregunta.tipo_de_pregunta == 2 ? (<div width={150} style={{ display: 'flex', height: '100%', paddingLeft: 10, alignItems: 'center', justifySelf: 'center' }} ><label   >{puntaje.pregunta}</label></div>) : null}

                                </div>
                                <div className="progress-bar  ">
                                    <div className="progress " style={{ width: `${puntaje.puntaje * 100}%` }}>

                                    </div>
                                    <p>{(puntaje.puntaje * 100).toFixed(0) + '%'}</p>
                                </div>


                            </div>
                        ))}
                    </div>
                </div >

            ))
            }





            {/* <div>
                {isLogged && idFormulario == null && (<ListadoPage handleVerFormulario={handleVerFormulario} ></ListadoPage>)}
            </div>
            <div>
                {isLogged && idFormulario != null && (<VistaFormularioPage handleVerFormulario={handleVerFormulario} idFormulario={idFormulario}></VistaFormularioPage>)}
            </div> */}

        </div >


    )
}

export default ResultadosPage;