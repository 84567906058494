
import './Boton.css'
import React, { useState, useEffect } from 'react';
import { getEncuestas } from '../../services/getEncuestas'
import iconEdit from './../../assets/iconos/icon-edit.svg';
import iconHistory from './../../assets/iconos/icon-history.svg';
import iconEncuestaEnabled from './../../assets/iconos/encuesta-enabled.svg';
import iconEncuestaDisabled from './../../assets/iconos/encuesta-disabled.svg';
import iconExportar from './../../assets/iconos/icon-exportar.svg';
import { setEncuestaActiva } from '../../services/setEncuestaActiva';
import { BrowserRouter as Router, Link, Route, Switch, Form, redirect, Routes, useNavigate, useHistory } from 'react-router-dom';
import { ICONOS_BOTON } from '../../utils/Const';

function Boton(props) {



    return (
        <div className='boton-fondo' >
            <img width={30} src={
                props.icono == ICONOS_BOTON.RESULTADOS ? iconHistory :
                    props.icono == ICONOS_BOTON.ENCUESTAENABLED ? iconEncuestaEnabled :
                        props.icono == ICONOS_BOTON.ENCUESTADISABLED ? iconEncuestaDisabled :
                            props.icono == ICONOS_BOTON.EXPORTAR ? iconExportar : null
            }></img>
            <p>{props.texto}</p>
        </div >


    )
}

export default Boton;