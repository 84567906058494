
import './NuevaEncuestaPage.css'
import React, { useState, useEffect } from 'react';
import { getEncuestas } from '../../services/getEncuestas'
import logo from './../../assets/iconos/logo.png';
import { setEncuestaActiva } from '../../services/setEncuestaActiva';
import { getEncuestaFromServidor } from '../../services/getEncuestaFromServidor'
import { useLocation } from 'react-router-dom';
import { getHistoria } from '../../services/getHistoria';
import { BrowserRouter as Router, Link, Route, Switch, Form, redirect, Routes, useNavigate, useHistory } from 'react-router-dom';


import icono_1 from './../../assets/iconos/cara_1.png';
import icono_2 from './../../assets/iconos/cara_2.png';
import icono_3 from './../../assets/iconos/cara_3.png';
import icono_4 from './../../assets/iconos/cara_4.png';
import icono_5 from './../../assets/iconos/cara_5.png';


import { useSelector } from 'react-redux';
import { postNuevaEncuesta } from '../../services/postNuevaEncuesta';
import { postActualizarEncuesta } from '../../services/postActualizarEncuesta';


function NuevaEncuestaPage() {

    const navigate = useNavigate();
    const userData = useSelector((state) => state.authInfo);
    const { state } = useLocation();
    const encuesta = state?.encuesta || null;
    console.log(encuesta ? encuesta : "No hay encuesta que mostrar")
    const [error, setError] = React.useState("")

    const [formData, setFormData] = React.useState({
        titulo: "",
        descripcion: "",
        preguntas: []
    })

    useEffect(() => {
        buscaEncuestaEnServidor()
    }, [encuesta])

    const buscaEncuestaEnServidor = () => {

        if (encuesta && encuesta.id_encuesta != null) {
            const fetchData = async () => {
                try {
                    const resultado = await getEncuestaFromServidor(userData, encuesta.id_encuesta);
                    console.log(resultado)
                    if (resultado)
                        // props.handleLogged(isLogged)
                        setFormData(resultado)
                    else
                        setError("Usuario/Password incorrecto")
                } catch (error) {
                    setError(error.message);
                }
            };
            fetchData();
        }

    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePreguntaChange = (index, e) => {
        const { name, value } = e.target;
        const preguntasCopy = [...formData.preguntas];
        preguntasCopy[index][name] = value;
        // console.log({ ...formData, preguntas: preguntasCopy })
        setFormData({ ...formData, preguntas: preguntasCopy });
    };

    const handleTipoPreguntaChange = (index, e) => {
        const { value } = e.target;
        const preguntasCopy = [...formData.preguntas];
        preguntasCopy[index].tipo_de_pregunta = parseInt(value);
        setFormData({ ...formData, preguntas: preguntasCopy });
    };

    const handleAgregarPregunta = () => {
        setFormData({
            ...formData,
            preguntas: [
                ...formData.preguntas,
                { pregunta: '', grupo: '', tipo_de_pregunta: 1, alternativas: [] },
            ],
        });
    };


    const guardarNuevaEncuesta = () => {

        let sw = 1
        setError("")
        if (formData.titulo == "") {
            sw = 0
            setError("Falta el titulo")
        }
        if (sw == 1 && formData.descripcion == "") {
            sw = 0
            setError("Falta la descripción")
        }

        if (sw == 1 && formData.preguntas.length == 0) {
            sw = 0
            setError("No existe ninguna pregunta ingresada")
        }

        if (sw == 1 && (formData.preguntas.some((pregunta) => !pregunta.pregunta.trim()))) {
            sw = 0
            setError("Ninguna pregunta puede estar vacia")
        }

        console.log("Encuesta antes de ordenar", formData)
        formData.preguntas.forEach(pregunta => {
            if (pregunta.tipo_de_pregunta === 2) {
                console.log("holi")
                pregunta.alternativas = reorganizarJson(pregunta.alternativas);
            }
        });
        console.log("Encuesta despues de ordenar", formData)

        if (sw == 1) {
            const fetchData = async () => {
                try {
                    let resultado = null
                    if (encuesta && encuesta.id_encuesta) {
                        resultado = await postActualizarEncuesta(userData, formData, encuesta.id_encuesta);
                    } else {
                        resultado = await postNuevaEncuesta(userData, formData);
                    }

                    console.log("resultado", resultado)
                    if (resultado)
                        // props.handleLogged(isLogged)

                        navigate("/");
                    else
                        setError("Usuario/Password incorrecto")
                } catch (error) {
                    setError(error.message);
                }
            };
            fetchData();
        }

    }

    const reorganizarJson = (data) => {
        console.log(data)
        const conOpcion = [];
        const sinOpcion = [];

        data.forEach((item) => {
            if (item) {
                if (item.opcion !== "") {
                    conOpcion.push(item);
                } else {
                    sinOpcion.push(item);
                }
            }

        });

        const jsonReorganizado = conOpcion.concat(sinOpcion);
        jsonReorganizado.forEach((item, index) => {
            item.id_alternativa = index + 1;
        });

        return jsonReorganizado;
    }

    const removeQuestion = (indexToRemove) => {
        setFormData((prevFormData) => {
            const updatedQuestions = [...prevFormData.preguntas];
            updatedQuestions.splice(indexToRemove, 1);

            return {
                ...prevFormData,
                preguntas: updatedQuestions,
            };
        });
    };

    console.log("formdata", formData)

    return (
        <div className="App" style={{ marginLeft: "20px" }}>
            {/* <div>
                {!isLogged && (<LoginPage handleLogged={handleLogged}></LoginPage>)}
            </div> */}
            <h1>
                {encuesta ? "Modificar encuesta" : "Nueva encuesta"}
            </h1>

            <table>
                <tr><td>Titulo:</td><td><input
                    type="text"
                    id="titulo"
                    name="titulo"
                    value={formData.titulo}
                    onChange={handleInputChange}
                    required
                /></td></tr>

                <tr><td>Descripción:</td><td>
                    <input
                        type="text"
                        id="descripcion"
                        name="descripcion"
                        value={formData.descripcion}
                        onChange={handleInputChange}
                        required
                    />
                </td></tr>


            </table>

            <h2>
                Preguntas
            </h2>


            {formData.preguntas.map((pregunta, index) => (
                <div key={index} className='div-pregunta' >
                    <label>Pregunta:</label>
                    <input
                        type="text"
                        name="pregunta"
                        value={pregunta.pregunta}
                        onChange={(e) => handlePreguntaChange(index, e)}
                    />
                    <br />
                    <label>Grupo (opcional):</label>
                    <input
                        type="text"
                        name="grupo"
                        value={pregunta.grupo}
                        onChange={(e) => handlePreguntaChange(index, e)}
                    />
                    <br />
                    <label>Tipo de pregunta:</label>
                    <select
                        name="tipo_de_pregunta"
                        value={pregunta.tipo_de_pregunta}
                        onChange={(e) => handleTipoPreguntaChange(index, e)}
                    >
                        <option value={1}>Evaluación</option>
                        <option value={2}>Opciones múltiples</option>
                    </select>

                    {pregunta.tipo_de_pregunta === 2 && (
                        <div>
                            <h3>Alternativas:  </h3>
                            {[{ id_alternativa: 1, opcion: pregunta.alternativas[0] ? pregunta.alternativas[0].opcion : '' },
                            { id_alternativa: 2, opcion: pregunta.alternativas[1] ? pregunta.alternativas[1].opcion : '' },
                            { id_alternativa: 3, opcion: pregunta.alternativas[2] ? pregunta.alternativas[2].opcion : '' },
                            { id_alternativa: 4, opcion: pregunta.alternativas[3] ? pregunta.alternativas[3].opcion : '' },
                            { id_alternativa: 5, opcion: pregunta.alternativas[4] ? pregunta.alternativas[4].opcion : '' }].map((i) => (
                                <input
                                    key={i.id_alternativa}
                                    type="text"
                                    value={i.opcion}
                                    placeholder={`Alternativa ${i.id_alternativa}`}
                                    onChange={(e) => {
                                        console.log(formData)
                                        const alternativasCopy = [...pregunta.alternativas];
                                        alternativasCopy[i.id_alternativa - 1] = { id_alternativa: i.id_alternativa, opcion: e.target.value };
                                        handlePreguntaChange(index, {
                                            target: { name: 'alternativas', value: alternativasCopy },
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    )}

                    <br />
                    <button type="button" onClick={() => removeQuestion(index)}>
                        Eliminar pregunta
                    </button>
                </div>
            ))}
            <button type="button" onClick={handleAgregarPregunta}>
                Agregar nueva pregunta
            </button>

            <button type="button" onClick={guardarNuevaEncuesta}>
                Guardar encuesta
            </button>

            <p style={{ color: 'red', fontSize: "12px" }}>
                {error}
            </p>

            {/* <div className='header'>
                <p style={{ width: "600px", fontWeight: 'bold', fontSize: '16px' }}>Pregunta</p>
                <p style={{ width: "300px", fontWeight: 'bold', fontSize: '16px' }}>Resultado</p>
            </div> */}

        </div >
    )
}

export default NuevaEncuestaPage;