// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-resultados {
  display: flex;
  flex-direction: row;
}
/* 
.div-pregunta {
  width: 600px;
}
.div-resultado {
  width: 300px;
  display: flex;
  flex-direction: column;
}

.div-puntaje {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
} */
/* 
.barraEstilo {
  display: flex;
  width: 100px;
  background: #b28e8a;
  border-radius: 10px;
  height: 20px;

  transition: width 0.5s ease-in-out;
  position: relative;
}

.textoEstilo {
  display: flex;
  background-color: green;
  align-self: flex-start;
  justify-self: flex-start;
  justify-items: flex-start;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
} */

.progress-bar {
  width: 100%;
  height: 50px;
  background-color: #ebcdcd;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.progress-bar .progress {
  display: flex;
  height: 100%;
  background-color: #4caf50;
  border-radius: 10px;
}

.progress-bar p {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  width: auto;
  color: white;

  text-shadow: 4px 4px 9px rgba(0, 0, 0, 2); /* Agrega una sombra de texto */
}
`, "",{"version":3,"sources":["webpack://./src/pages/nueva-encuesta/NuevaEncuestaPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;;;;;;;;;;;;;;GAcG;AACH;;;;;;;;;;;;;;;;;;;;;;;GAuBG;;AAEH;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,WAAW;EACX,YAAY;;EAEZ,yCAAyC,EAAE,+BAA+B;AAC5E","sourcesContent":[".div-resultados {\n  display: flex;\n  flex-direction: row;\n}\n/* \n.div-pregunta {\n  width: 600px;\n}\n.div-resultado {\n  width: 300px;\n  display: flex;\n  flex-direction: column;\n}\n\n.div-puntaje {\n  margin-top: 10px;\n  display: flex;\n  flex-direction: row;\n} */\n/* \n.barraEstilo {\n  display: flex;\n  width: 100px;\n  background: #b28e8a;\n  border-radius: 10px;\n  height: 20px;\n\n  transition: width 0.5s ease-in-out;\n  position: relative;\n}\n\n.textoEstilo {\n  display: flex;\n  background-color: green;\n  align-self: flex-start;\n  justify-self: flex-start;\n  justify-items: flex-start;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  color: #fff;\n} */\n\n.progress-bar {\n  width: 100%;\n  height: 50px;\n  background-color: #ebcdcd;\n  border-radius: 10px;\n  overflow: hidden;\n  position: relative;\n}\n\n.progress-bar .progress {\n  display: flex;\n  height: 100%;\n  background-color: #4caf50;\n  border-radius: 10px;\n}\n\n.progress-bar p {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  right: 0px;\n  text-align: center;\n  width: auto;\n  color: white;\n\n  text-shadow: 4px 4px 9px rgba(0, 0, 0, 2); /* Agrega una sombra de texto */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
