
import './LoginPage.css'
import React, { useState, useEffect } from 'react';
import { getAuth } from '../../services/getAuth'
import { BrowserRouter as Router, Route, Switch, Form, redirect, Routes, useNavigate, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { iniciarSesion } from '../../redux/userActions';


function LoginPage(props) {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        usuario: '',
        password: ''
    });


    const login = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const userInfo = await getAuth(formData.usuario, formData.password);
                // console.log(userInfo.data)
                // console.log("authorization", userInfo.headers.getAuthorization)
                if (userInfo) {
                    dispatch(iniciarSesion(userInfo.data));
                    // props.handleLogged(userInfo.data)
                }
                // navigate("/panel");
                else
                    setError("Usuario/Password incorrecto")
            } catch (error) {
                // setError(error.message);
                setError("Usuario/Password incorrecto")
            }
        };
        fetchData();
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const registrar = () => {
        navigate("/registrar");
    }

    return (
        <div>
            <h1>
                Login
            </h1>

            <table>
                <tr><td>Usuario:</td><td><input
                    type="text"
                    id="usuario"
                    name="usuario"
                    value={formData.usuario}
                    onChange={handleInputChange}
                    required
                /></td></tr>

                <tr><td>Password:</td><td>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                    />
                </td></tr>


            </table>


            <button onClick={login} type="submit">Login</button>
            <p style={{ color: 'red', fontSize: "12px" }}>
                {error}
            </p>

            <a onClick={() => { registrar() }}><label className='linkDeTexto'>Registrar clave</label></a>



        </div >
    )
}

export default LoginPage;