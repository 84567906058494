
import axios from 'axios';

// Función para realizar la solicitud GET a la API

export const enviarFormulario = async (hashedIdLicencia, correo, nombreUsuario, contrasena, institucion) => {
    try {

        const response = await axios.post(`${process.env.REACT_APP_SERVER_BACKEND}/v1/registrar`,
            {
                hashedIdLicencia: hashedIdLicencia,
                nombreUsuario: nombreUsuario,
                correo: correo,
                contrasena: contrasena,
                institucion: institucion
            });

        if (response.status === 200) {
            console.log("response.data", response)
            return response;
        } else {
            throw new Error(`Error en la solicitud. Código de estado: ${response.status}`);
        }


    } catch (error) {
        console.error('Error al obtener los datos:', error);
        throw new Error('Error al obtener los datos ' + error);
    }
};
