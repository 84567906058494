import logo from './assets/iconos/logo.png';
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Form, redirect, Routes, useNavigate, useHistory, Navigate } from 'react-router-dom';
import PanelPage from './pages/panel/Panel'
import LoginPage from './pages/login/LoginPage';
import ResultadosPage from './pages/resultados/ResultadosPage';
import RegistrarPage from './pages/registrar/RegistrarPage'
import NuevaEncuestaPage from './pages/nueva-encuesta/NuevaEncuestaPage'
import HeaderComponent from './components/header/HeaderComponent';
import { useSelector } from 'react-redux';
// import { Provider } from 'react-redux'

const App = () => {

  // const [paginaActiva, setPaginaActiva] = React.useState("login")
  const navigate = useNavigate();
  // const handleLogged = (handleLoggedValue) => {
  //   console.log("handleLoggedValue", handleLoggedValue)
  //   // useHistory.push('/panel');
  //   navigate("/panel");
  // }
  const userData = useSelector((state) => state.authInfo);
  console.log(userData)

  return (




    <div className="App">
      {/* <Provider> */}
      <div className='app-header'>
        <HeaderComponent></HeaderComponent>
      </div>

      <div className='app-cuerpo'>

        {/* element={userData ? <Navigate to="/panel" /> : <LoginPage />} */}
        <Routes>
          <Route path="/" element={userData.estaAutenticado ? <Navigate to="/panel" /> : <LoginPage />} />
          <Route path="/login" element={userData.estaAutenticado ? <Navigate to="/panel" /> : <LoginPage />} />
          <Route path="/registrar" element={userData.estaAutenticado ? <Navigate to="/panel" /> : <RegistrarPage />} />
          <Route path="/registrar/crear-cuenta" element={userData.estaAutenticado ? <Navigate to="/panel" /> : <RegistrarPage />} />

          <Route path="/panel" element={!userData.estaAutenticado ? <Navigate to="/login" /> : <PanelPage />} />

          <Route path="/panel/resultados" element={!userData.estaAutenticado ? <Navigate to="/login" /> : <ResultadosPage />} />
          <Route path="/panel/nueva-encuesta" element={!userData.estaAutenticado ? <Navigate to="/login" /> : <NuevaEncuestaPage />} />
          <Route path="/panel/modificar-encuesta" element={!userData.estaAutenticado ? <Navigate to="/login" /> : <NuevaEncuestaPage />} />
        </Routes>

      </div>
      {/* </Provider> */}

    </div>
  );
}

export default App;
