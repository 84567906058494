export const iniciarSesion = (usuario) => ({
    type: 'INICIAR_SESION',
    payload: usuario,
});

export const cerrarSesion = () => ({
    type: 'CERRAR_SESION',
});

// export const setToken = (token) => ({
//     type: 'SET_TOKEN',
//     payload: token,
// });
