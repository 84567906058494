
import './HeaderComponent.css'
import React, { useState, useEffect } from 'react';
import { getEncuestas } from '../../services/getEncuestas'
import iconEdit from './../../assets/iconos/icon-edit.svg';
import logo from './../../assets/iconos/logo.png';
import IconoCerrarSesion from './../../assets/iconos/cerrar-sesion.svg';
import iconEncuestaEnabled from './../../assets/iconos/encuesta-enabled.svg';
import iconEncuestaDisabled from './../../assets/iconos/encuesta-disabled.svg';
import { setEncuestaActiva } from '../../services/setEncuestaActiva';
import { BrowserRouter as Router, Link, Route, Switch, Form, redirect, Routes, useNavigate, useHistory } from 'react-router-dom';
import { ICONOS_BOTON } from '../../utils/Const';
import { useSelector, useDispatch } from 'react-redux';
import { cerrarSesion } from './../../redux/userActions'


function HeaderComponent(props) {

    const dispatch = useDispatch();

    const userData = useSelector((state) => state.authInfo);
    console.log(userData)

    const desloguear = () => {
        console.log("Desloguear")
        dispatch(cerrarSesion());
    }

    return (
        <div className='header-component'  >
            <img className='logo' src={logo} alt="logo" />
            {
                userData.estaAutenticado && (
                    <div className='info-usuario'>
                        <label className='linkDeTexto'>{userData.usuario.nombre}</label>

                        <a onClick={() => desloguear()}>
                            <img className='logo' src={IconoCerrarSesion} h alt="logo" />
                        </a>
                    </div>
                )
            }


        </div >


    )
}

export default HeaderComponent;