const initialState = {
    usuario: null,
    estaAutenticado: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INICIAR_SESION':
            return {
                ...state,
                usuario: action.payload,
                estaAutenticado: true,
            };
        case 'CERRAR_SESION':
            return {
                ...state,
                usuario: null,
                estaAutenticado: false,
            };
        // case 'SET_TOKEN':
        //     return {
        //         ...state,
        //         token: action.payload
        //     };
        default:
            return state;
    }
};

export default userReducer;