import axios from 'axios';

// Función para realizar la solicitud GET a la API
export const getResultadoEncuestaEnExcel = async (userData, id_encuesta) => {



    try {

        const response = await axios.get(`${process.env.REACT_APP_SERVER_BACKEND}/v1/encuestas/${userData.usuario.hashedIdInstitucion}/respuestas/${id_encuesta}/exportar-excel`, {
            headers: {
                Authorization: `Bearer ${userData.usuario.token}`,

            },
            responseType: 'blob'
        });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Crear un enlace para descargar el archivo
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Datos exportados ' + id_encuesta + '.xlsx');
            document.body.appendChild(link);

            // Simular un clic en el enlace para iniciar la descarga
            link.click();

            // Liberar el objeto URL
            window.URL.revokeObjectURL(url);

            console.log("response.data", response.data)
            return response.data;
        } else {
            throw new Error(`Error en la solicitud. Código de estado: ${response.status}`);
        }


    } catch (error) {
        console.error('Error al obtener los datos:', error);
        throw new Error('Error al obtener los datos ' + error);
    }
};
