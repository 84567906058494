import axios from 'axios';

// Función para realizar la solicitud GET a la API
export const postNuevaEncuesta = async (userData, formData) => {
    try {
        // console.log("validarCodigoCorreo", validarCodigoCorreo)
        console.log("asdsd", `${process.env.REACT_APP_SERVER_BACKEND}/v1/encuestas/${userData.usuario.hashedIdInstitucion}`)
        const response = await axios.post(`${process.env.REACT_APP_SERVER_BACKEND}/v1/encuestas/${userData.usuario.hashedIdInstitucion}`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${userData.usuario.token}`,
                }
            });

        if (response.status === 200) {
            console.log("response.data", response)
            return response;
        } else {
            console.log("response.data", response)
            throw new Error(`Error en la solicitud. Código de estado: ${response.status}`);
        }


    } catch (error) {
        console.error('Error al obtener los datos:', error);
        throw new Error('Error al obtener los datos ' + error);
    }
};
