
import './RegistrarPage.css'
import React, { useState, useEffect } from 'react';
import { registrarLicencia } from '../../services/registrarLicencia'
import { enviarCodigoVerificacionAlCorreo } from '../../services/enviarCodigoVerificacionAlCorreo'
import { validarCodigoCorreo } from '../../services/validarCodigoCorreo'
import { enviarFormulario } from '../../services/enviarFormulario'
import { BrowserRouter as Router, Route, Switch, Form, redirect, Routes, useNavigate, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { iniciarSesion } from '../../redux/userActions';


function RegistrarPage(props) {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [inputCorreoVisible, setInputCorreoVisible] = useState(false);
    const [inputFormulario, setInputFormulario] = useState(false);
    const [inputCodigoVerificacionCorreoVisible, setInputCodigoVerificacionCorreoVisible] = useState(false)
    const [formData, setFormData] = useState({
        codigoLicencia: '',
        hashedIdLicencia: '',
        correo: '',
        codigoValidacionCorreo: '',
        nombreUsuario: '',
        contrasena: '',
        contrasena2: '',
        institucion: ''
    });


    const login = () => {
        // console.log("asds")
        setError("")
        setInputCorreoVisible(false)
        const fetchData = async () => {
            try {
                const response = await registrarLicencia(formData.codigoLicencia);
                console.log(response.data)
                if (response.data.hashedIdLicencia == -1) {
                    setError("El código de licencia no existe (o ya fué utilizado)")

                } else {
                    formData.hashedIdLicencia = response.data.hashedIdLicencia
                    setInputCorreoVisible(true)
                }
                // navigate("/panel");

            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }


    const enviarValidacionCorreo = () => {
        // console.log("asds")
        setError("")
        const fetchData = async () => {
            try {
                const response = await enviarCodigoVerificacionAlCorreo(formData.hashedIdLicencia, formData.correo);
                console.log(response.data)
                if (!response.data.respuesta) {
                    setError("Error al enviar el código. Revise que el correo sea valido")
                    setInputCodigoVerificacionCorreoVisible(false)
                } else {
                    setInputCodigoVerificacionCorreoVisible(true)
                }
                // navigate("/panel");

            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }


    const verificarCodigoCorreo = () => {
        // console.log("asds")
        setError("")
        const fetchData = async () => {
            try {
                const response = await validarCodigoCorreo(formData.hashedIdLicencia, formData.correo, formData.codigoValidacionCorreo);
                console.log(response.data)
                if (!response.data.respuesta) {
                    setError("Código invalido")
                    setInputFormulario(false)
                    // setInputCodigoVerificacionCorreoVisible(false)
                } else {
                    setInputFormulario(true)
                    // setInputCodigoVerificacionCorreoVisible(true)
                }
                // navigate("/panel");

            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    }


    const verificarYEnviarFormulario = () => {
        console.log("Verificadndo")
        setError("")
        let sw = true
        if (formData.nombreUsuario.length == 0) {
            sw = false
            setError("Falta ingresar el nombre de usuario")
        }
        if (sw && formData.correo.length == 0) {
            sw = false
            setError("Falta ingresar el correo")
        }
        if (sw && formData.contrasena != formData.contrasena2) {
            sw = false
            setError("Las contraseñas no coinciden")
        }
        if (sw && formData.contrasena.length < 8) {
            sw = false
            setError("Las contraseña debe tener un mínimo de 8 caracteres")
        }
        if (sw && formData.institucion.length == 0) {
            sw = false
            setError("Falta ingresar la institución")
        }
        if (sw) {
            const fetchData = async () => {
                try {
                    const response = await enviarFormulario(formData.hashedIdLicencia, formData.correo, formData.nombreUsuario, formData.contrasena, formData.institucion);
                    console.log(response.data)
                    if (!response.data.respuesta) {
                        setError("Error al procesar formulario")

                        // setInputCodigoVerificacionCorreoVisible(false)
                    } else {
                        setMensaje("Registrado correctamente")
                        // setInputCodigoVerificacionCorreoVisible(true)
                    }
                    // navigate("/panel");

                } catch (error) {
                    setError(error.message);
                }
            };
            fetchData();
        }

    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    return (
        <div>
            <h1>
                Registrar clave
            </h1>

            <table>
                <tr><td>Código de Licencia Spoti:</td><td><input
                    type="text"
                    id="codigoLicencia"
                    name="codigoLicencia"
                    value={formData.codigoLicencia}
                    onChange={handleInputChange}
                    required
                /></td></tr>
                <tr><td>
                    <button onClick={login} type="submit">Registrar</button>
                </td></tr>

                {inputCorreoVisible ? (
                    <tr><td>Correo al que quedará registrado:</td><td><input
                        type="text"
                        id="correo"
                        name="correo"
                        value={formData.correo}
                        onChange={handleInputChange}
                        required
                    /></td></tr>

                ) : ""
                }
                {inputCorreoVisible ? (
                    <tr><td><button onClick={enviarValidacionCorreo} type="submit">Enviar código de verificación al correo</button></td></tr>

                ) : ""
                }

                {inputCodigoVerificacionCorreoVisible ? (
                    <tr><td>Código de verificación enviado al correo:</td><td><input
                        type="text"
                        id="codigoValidacionCorreo"
                        name="codigoValidacionCorreo"
                        value={formData.codigoValidacionCorreo}
                        onChange={handleInputChange}
                        required
                    /></td></tr>

                ) : ""
                }
                {inputCodigoVerificacionCorreoVisible ? (
                    <tr><td><button onClick={verificarCodigoCorreo} type="submit">Verificar</button></td></tr>

                ) : ""
                }


            </table>

            {inputFormulario ? (
                <div>

                    <h4>Información del usuario</h4>
                    <table>
                        <tr><td>Nombre completo del usuario</td><td><input
                            type="text"
                            id="nombreUsuario"
                            name="nombreUsuario"
                            value={formData.nombreUsuario}
                            onChange={handleInputChange}
                            required
                        /></td></tr>

                        <tr><td>Contraseña</td><td><input
                            type="password"
                            id="contrasena"
                            name="contrasena"
                            value={formData.contrasena}
                            onChange={handleInputChange}
                            required
                        /></td></tr>
                        <tr><td>Repita Contraseña</td><td><input
                            type="password"
                            id="contrasena2"
                            name="contrasena2"
                            value={formData.contrasena2}
                            onChange={handleInputChange}
                            required
                        /></td></tr>



                    </table>
                    <h4>Información de la institución</h4>
                    <table>
                        <tr><td>Nombre institución</td><td><input
                            type="text"
                            id="institucion"
                            name="institucion"
                            value={formData.institucion}
                            onChange={handleInputChange}
                            required
                        /></td></tr>

                    </table>

                    <tr><td><button onClick={verificarYEnviarFormulario} type="submit">Finalizar</button></td></tr>
                </div>




            ) : ""}





            <p style={{ color: 'red', fontSize: "12px" }}>
                {error}
            </p>

            {mensaje != "" ? (
                <div>
                    <p style={{ color: 'blue' }}>
                        {mensaje}
                    </p>

                    <a onClick={() => { navigate("/login"); }}><label className='linkDeTexto'>Volver al Login</label></a>

                </div>

            ) : ""}



        </div >
    )
}

export default RegistrarPage;