// rootReducer.js

import { combineReducers } from 'redux';
import userReducer from './userReducer';

const rootReducer = combineReducers({
    authInfo: userReducer,
    // Otros reductores...
});

export default rootReducer