import axios from 'axios';

// Función para realizar la solicitud GET a la API
export const setEncuestaActiva = async (userData, id_encuesta) => {
    try {

        // console.log(process.env)
        // const idUsuario = 1
        // console.log(process.env.REACT_APP_SERVER_BACKEND)
        // console.log(`${process.env.REACT_APP_SERVER_BACKEND}/v1/formulario`)
        console.log(`${process.env.REACT_APP_SERVER_BACKEND}/v1/encuestas/${userData.usuario.hashedIdInstitucion}/activas`)
        const response = await axios.put(`${process.env.REACT_APP_SERVER_BACKEND}/v1/encuestas/${userData.usuario.hashedIdInstitucion}/activas`,
            { id_encuesta: id_encuesta },
            {
                headers: {
                    Authorization: `Bearer ${userData.usuario.token}`,
                },
            });

        if (response.status === 200) {
            console.log("response.data", response.data)
            return response.data;
        } else {
            throw new Error(`Error en la solicitud. Código de estado: ${response.status}`);
        }


    } catch (error) {
        console.error('Error al obtener los datos:', error);
        throw new Error('Error al obtener los datos ' + error);
    }
};
