import axios from 'axios';

// Función para realizar la solicitud GET a la API
export const getAuth = async (usuario, password) => {
    try {

        console.log("SHola")
        // console.log(process.env)
        // const idUsuario = 1
        // console.log(process.env.REACT_APP_SERVER_BACKEND)
        // console.log(`${process.env.REACT_APP_SERVER_BACKEND}/v1/formulario`)
        const response = await axios.post(`${process.env.REACT_APP_SERVER_BACKEND}/v1/auth`, { usuario: usuario, password: password });

        if (response.status === 200) {
            console.log("response.data", response)
            return response;
        } else {
            throw new Error(`Error en la solicitud. Código de estado: ${response.status}`);
        }


    } catch (error) {
        console.error('Error al obtener los datos:', error);
        throw new Error('Error al obtener los datos ' + error);
    }
};
