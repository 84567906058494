
import './Panel.css'
import React, { useState, useEffect } from 'react';
import { getEncuestas } from '../../services/getEncuestas'
import logo from './../../assets/iconos/logo.png';
import { setEncuestaActiva } from '../../services/setEncuestaActiva';
import { BrowserRouter as Router, Link, Route, Switch, Form, redirect, Routes, useNavigate, useHistory } from 'react-router-dom';
import Boton from '../../components/boton/Boton';
import { ICONOS_BOTON } from '../../utils/Const';
import { useSelector, useDispatch } from 'react-redux';
import { cerrarSesion } from './../../redux/userActions'


function PanelPage(props) {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const userData = useSelector((state) => state.authInfo);


    const [encuestas, setEncuestas] = useState([])
    const [encuestasFiltradas, setEncuestasFiltradas] = useState([])
    const [isLogged, setIsLogged] = useState(false)
    const [error, setError] = useState("")
    const [filtro, setFiltro] = useState("")
    const [idFormulario, setIdFormulario] = useState(null)
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        buscaEncuestas()
    }, [])

    useEffect(() => {
        buscaEncuestas()
    }, [filtro])

    useEffect(() => {
        usaFiltro()
    }, [encuestas])

    const usaFiltro = () => {
        setEncuestasFiltradas(encuestas.filter(x => x.descripcion_larga.toUpperCase().includes(filtro.toUpperCase()) || x.glosa_encuesta.toUpperCase().includes(filtro.toUpperCase())))
    }

    const buscaEncuestas = () => {
        // console.log("asds")
        const fetchData = async () => {
            try {
                const resultado = await getEncuestas(userData);
                console.log(resultado)
                if (resultado)
                    // props.handleLogged(isLogged)
                    setEncuestas(resultado)
                else
                    setError("Usuario/Password incorrecto")
            } catch (error) {
                console.log("Se cayó", error.message)
                if (error.message == 401) {
                    console.log("Se cayó 2")

                    dispatch(cerrarSesion());
                }
                setError(error.message);
            }
        };
        fetchData();
    }

    const handleTextoChange = (event) => {
        setFiltro(event.target.value)
    }

    const handleCheckboxChange = (id_encuesta) => {
        console.log("Cambiando id_encuesta", id_encuesta)
        const fetchData = async () => {
            try {
                const resultado = await setEncuestaActiva(userData, id_encuesta);
                console.log("resultado", resultado)
                if (resultado)
                    // props.handleLogged(isLogged)
                    buscaEncuestas()
                else
                    setError("Usuario/Password incorrecto")
            } catch (error) {
                setError(error.message);
            }
        };
        fetchData();
    };

    const modificarEncuesta = () => {

    }


    const abreHistorial = (encuesta) => {
        console.log("Abriendo historia", { encuesta })

        // navigate("/resultados");
        navigate('/resultados', { state: { encuesta: encuesta } })

    }


    return (
        <div className="App">
            {/* <div>
                {!isLogged && (<LoginPage handleLogged={handleLogged}></LoginPage>)}
            </div> */}
            <h1>
                Panel de control
            </h1>

            <h2>Administra las encuestas</h2>
            <h3>Llave de conexión: {userData.usuario.hashedIdInstitucion}</h3>

            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                <input type='text' className='txtBuscar icono-input' placeholder='Filtrar por nombre...' value={filtro} onChange={handleTextoChange} />

                <Link
                    to={`nueva-encuesta`}

                ><p><Boton icono={ICONOS_BOTON.RESULTADOS} texto={'Nueva encuesta'}  ></Boton></p></Link>



            </div>

            <div className='header'>
                <p>Encuesta</p>
                <p>Activación</p>
                <p>Modificar</p>
                <p>Ver resultados</p>
            </div>

            {encuestasFiltradas.map((encuesta, index) => (
                <div className='encuesta' key={index}>
                    <div className={encuesta.Activa == 1 ? 'activa' : ''} >
                        <div>{encuesta.glosa_encuesta}</div>
                        <div>{encuesta.descripcion_larga}</div>

                    </div>
                    <div>
                        {/* <div className={`checkbox-container ${encuesta.Activa == 1 ? 'checked' : ''}`}>
                            <input
                                type="checkbox"
                                id={"micheckbox" + encuesta.id_encuesta}
                                checked={encuesta.Activa}
                                onChange={() => handleCheckboxChange(encuesta.id_encuesta)}
                            />
                            <label htmlFor={"micheckbox" + encuesta.id_encuesta}></label>
                        </div> */}

                        {/* <a onClick={() => handleCheckboxChange(encuesta.id_encuesta)}><p className='p-estado-encuesta'>{encuesta.Activa == 1 ? 'Activado' : 'Desactivado'} </p></a> */}
                        <a onClick={() => handleCheckboxChange(encuesta.id_encuesta)}><Boton icono={encuesta.Activa == 1 ? ICONOS_BOTON.ENCUESTAENABLED : ICONOS_BOTON.ENCUESTADISABLED} texto={encuesta.Activa == 1 ? 'Activado' : 'Desactivado'} onClick={() => modificarEncuesta(encuesta)}></Boton></a>

                    </div>

                    <div  >


                        {encuesta.modificable == 1 && (
                            // <div>
                            //     <a class="miboton" onClick={() => modificarEncuesta(encuesta)} >
                            //         <p>Modificar</p>
                            //         <img class="apps" src={logo} />
                            //     </a>
                            // </div>
                            // <Boton icono={ICONOS_BOTON.RESULTADOS} texto={"modificar-encuesta"} onClick={() => modificarEncuesta(encuesta)}></Boton>
                            <Link
                                to={`modificar-encuesta`}
                                state={{ encuesta: encuesta }} // <-- state prop
                                key={encuesta.id_encuesta}
                            ><p><Boton icono={ICONOS_BOTON.RESULTADOS} texto={"Modificar encuesta"}></Boton></p></Link>



                        )}

                    </div>
                    <div>

                        <Link
                            to={`resultados`}
                            state={{ encuesta: encuesta }} // <-- state prop
                            key={encuesta.id_encuesta}
                        ><p><Boton icono={ICONOS_BOTON.RESULTADOS} texto={"Ver resultados"}></Boton></p></Link>





                    </div>


                </div >

            ))
            }





            {/* <div>
                {isLogged && idFormulario == null && (<ListadoPage handleVerFormulario={handleVerFormulario} ></ListadoPage>)}
            </div>
            <div>
                {isLogged && idFormulario != null && (<VistaFormularioPage handleVerFormulario={handleVerFormulario} idFormulario={idFormulario}></VistaFormularioPage>)}
            </div> */}

        </div >


    )
}

export default PanelPage;