// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-component {
  display: flex;
  align-items: center;
  height: 100px;
  background: white;
  box-shadow: 10px 23px 18px -17px rgba(0, 0, 0, 0.28);
}

.header-component > .logo {
  margin-right: auto; /* Mueve el logo a la izquierda */
}

.header-component > .info-usuario {
  margin-left: auto; /* Mueve info-usuario a la derecha */
  font-weight: bold;
  font-size: 16px;
  color: gray;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header-component > .info-usuario a {
  margin-left: 10px;
  cursor: pointer;
  align-items: center;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/HeaderComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EAGjB,oDAAoD;AACtD;;AAEA;EACE,kBAAkB,EAAE,iCAAiC;AACvD;;AAEA;EACE,iBAAiB,EAAE,oCAAoC;EACvD,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".header-component {\n  display: flex;\n  align-items: center;\n  height: 100px;\n  background: white;\n  -webkit-box-shadow: 10px 23px 18px -17px rgba(0, 0, 0, 0.28);\n  -moz-box-shadow: 10px 23px 18px -17px rgba(0, 0, 0, 0.28);\n  box-shadow: 10px 23px 18px -17px rgba(0, 0, 0, 0.28);\n}\n\n.header-component > .logo {\n  margin-right: auto; /* Mueve el logo a la izquierda */\n}\n\n.header-component > .info-usuario {\n  margin-left: auto; /* Mueve info-usuario a la derecha */\n  font-weight: bold;\n  font-size: 16px;\n  color: gray;\n  margin-right: 20px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.header-component > .info-usuario a {\n  margin-left: 10px;\n  cursor: pointer;\n  align-items: center;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
