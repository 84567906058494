// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boton-fondo {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-radius: 20px;
  background-color: white;
  width: 200px;
  color: rgb(66, 66, 66);
  padding: 10px 10px 10px;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  border-color: var(--gris-texto);
  box-shadow: 10px 23px 18px -17px rgba(0, 0, 0, 0.28);
}

.boton-fondo:hover {
  background-color: rgb(232, 229, 229);
}

.boton-fondo > p {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/boton/Boton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,sBAAsB;EACtB,uBAAuB;EACvB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,+BAA+B;EAI/B,oDAAoD;AACtD;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".boton-fondo {\n  display: flex;\n  flex-direction: row;\n  cursor: pointer;\n  border-radius: 20px;\n  background-color: white;\n  width: 200px;\n  color: rgb(66, 66, 66);\n  padding: 10px 10px 10px;\n  border-width: 1px;\n  border-radius: 10px;\n  border-style: solid;\n  border-color: var(--gris-texto);\n\n  -webkit-box-shadow: 10px 23px 18px -17px rgba(0, 0, 0, 0.28);\n  -moz-box-shadow: 10px 23px 18px -17px rgba(0, 0, 0, 0.28);\n  box-shadow: 10px 23px 18px -17px rgba(0, 0, 0, 0.28);\n}\n\n.boton-fondo:hover {\n  background-color: rgb(232, 229, 229);\n}\n\n.boton-fondo > p {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
