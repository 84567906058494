// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  width: 100%;
}

.app-header {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 10;
}

.app-cuerpo {
  flex: 1 1;
  width: 100%;
  /* background-color: #61dafb; */
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.body {
  margin-left: 20px;
}

.App-header {
  height: auto;
  padding-left: 20px;
  padding-top: 10px;
  color: white;
  flex-direction: row;
}

.App-link {
  color: #61dafb;
}

.miboton {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.linkDeTexto {
  color: gray;
  cursor: pointer;
}

.linkDeTexto:hover {
  color: rgb(57, 55, 55);
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,MAAM;EACN,OAAO;EACP,QAAQ;EACR,aAAa;EACb,WAAW;AACb;;AAEA;EACE,SAAO;EACP,WAAW;EACX,+BAA+B;AACjC;;AAEA;;;GAGG;;AAEH;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".App {\n  width: 100%;\n}\n\n.app-header {\n  position: sticky;\n  width: 100%;\n  top: 0;\n  left: 0;\n  right: 0;\n  height: 100px;\n  z-index: 10;\n}\n\n.app-cuerpo {\n  flex: 1;\n  width: 100%;\n  /* background-color: #61dafb; */\n}\n\n/* .App-logo {\n  height: 40vmin;\n  pointer-events: none;\n} */\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.body {\n  margin-left: 20px;\n}\n\n.App-header {\n  height: auto;\n  padding-left: 20px;\n  padding-top: 10px;\n  color: white;\n  flex-direction: row;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n.miboton {\n  cursor: pointer;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.linkDeTexto {\n  color: gray;\n  cursor: pointer;\n}\n\n.linkDeTexto:hover {\n  color: rgb(57, 55, 55);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
